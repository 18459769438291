<template lang="html">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-patient-bar></v-patient-bar>
        <v-image-capture v-model="formData.photoIDCard"></v-image-capture>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click="print">Upload</v-btn>
      </v-col>
      <v-col cols="12">
        {{ returndata }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
export default {
  data: ()=>({
    formData : {
      photoIDCard: null,
    },
    returndata: null,
  }),
  methods: {
    upload() {
      api.post(['assets','addBase64'],this.formData).then((returnData)=>{
        this.returndata = returnData
      })
    },
    print() {

      this.$loading('Printing',
      [api.get(['generic','Document','PrintController','printDocumentBase64']).then((returnData)=>{
        this.$printPdfBase64(returnData['data'][0])
      })]
      )
    }
  }
}
</script>

<style lang="css">
</style>
